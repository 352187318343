import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './styles/1authForm.scss';
import { useTheme } from './ThemeContext';
import NavigationBar from './NavigationBar';
import translations from './translate.js';

function SignUpPage() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        repeatPassword: ''
    });
    const [errorMessage, setErrorMessage] = useState('');
    const [isRegistering, setIsRegistering] = useState(false);

    const language = localStorage.getItem('language') || 'lv'; 
    const text = translations[language];

    const { theme } = useTheme();

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    const handleRegister = async (e) => {
        e.preventDefault();
        setIsRegistering(true);
        setErrorMessage(''); 
    
        const selectedLanguage = localStorage.getItem('language') || 'lv';
        
        try {
            const response = await axios.post(
                '/auth/signup',
                formData, 
                {
                    headers: { 
                        'Content-Type': 'application/json',
                        'language': selectedLanguage, 
                    },
                }
            );
    
            if (response.status === 201) {
                navigate('/verify/telegram'); 
            }
        } catch (error) {
            setIsRegistering(false);
            if (error.response && error.response.data && error.response.data.message) {
                setErrorMessage(error.response.data.message); 
            } else {
                setErrorMessage(text.signUpErrorGeneric); 
            }
        }
    };
    

    return (
        <div data-theme={theme}>
        <div>
            <NavigationBar />
            <div className="container-fluid" id='form-container'>
                <div className="card">
                    <div className="card-body">
                        <h2 className="text-uppercase text-center" id='signup-title'>{text.createAccountTitle}</h2>
                        <form onSubmit={handleRegister}>
                            
                            <div className="form-outline">
                                <label className="form-label">{text.usernameLabel}</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    name='username'
                                    value={formData.username} 
                                    onChange={handleChange} 
                                    required
                                />
                            </div>

                            <div className="form-outline">
                                <label className="form-label">{text.passwordLabel}</label>
                                <input 
                                    type="password" 
                                    className="form-control" 
                                    name='password'
                                    value={formData.password} 
                                    onChange={handleChange}
                                    required 
                                />
                            </div>

                            <div className="form-outline">
                                <label className="form-label">{text.confirmPasswordLabel}</label>
                                <input 
                                    type="password" 
                                    className="form-control" 
                                    name='repeatPassword'
                                    value={formData.repeatPassword} 
                                    onChange={handleChange}
                                    required 
                                />
                            </div>

                            <div className="d-flex justify-content-center" id='btn-container'>
                                <button 
                                    type="submit" 
                                    className="btn" 
                                    id='btn-form-submit'
                                    disabled={isRegistering}
                                >
                                    {isRegistering ? text.registeringText : text.signUpButton}
                                </button>
                            </div>

                            {errorMessage && (
                                <div className="error-message text-center mt-3">
                                    {errorMessage}
                                </div>
                            )}

                            <p className="text-center" id='footer-text'>{text.loginPrompt}
                                <Link to='/login' className="nav-link"><u>{text.loginLinkText}</u></Link>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}

export default SignUpPage;
