import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NavigationBar from './NavigationBar';
import { Table, Modal, Button, Alert } from 'react-bootstrap';
import { Eye } from 'react-bootstrap-icons'; 
import './styles/viewFeedbacks.scss';

const ViewFeedbacks = () => {
    const [feedbacks, setFeedbacks] = useState([]);
    const [selectedFeedback, setSelectedFeedback] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchFeedbacks = async () => {
            try {
                const response = await axios.get('/admin/get/feedbacks');
                setFeedbacks(response.data.feedbacks || []);
            } catch (error) {
                setError("Failed to load feedbacks. Please try again later.");
            } finally {
                setLoading(false);
            }
        };

        fetchFeedbacks();
    }, []);

    const handleOpenModal = (feedback) => {
        setSelectedFeedback(feedback);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedFeedback(null);
    };

    const getRatingClass = (rating) => {
        return `rating rating-${rating}`; // Simplified class name generation
    };

    return (
        <main>
            <NavigationBar />
            <div id="feedback-table-container">
                <h1>View Feedbacks</h1>

                {loading ? (
                    <div style={{ textAlign: 'center' }}>
                    <div className="loader"></div>
                    <p>Loading feedbacks...</p>
                </div>
                ) : error ? (
                    <Alert variant="danger">{error}</Alert>
                ) : (
                    <Table className="table table-hover">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Username</th>
                                <th>Feedback</th>
                                <th>Rating</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {feedbacks.length > 0 ? (
                                feedbacks.map((feedback) => (
                                    <tr key={feedback.id}>
                                        <td>{feedback.id}</td>
                                        <td>{feedback.username}</td>
                                        <td>{feedback.feedback}</td>
                                        <td className={getRatingClass(feedback.rating)}>{feedback.rating}</td>
                                        <td>
                                            <Button
                                                variant="info"
                                                onClick={() => handleOpenModal(feedback)}
                                            >
                                                <Eye />
                                            </Button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5" style={{ textAlign: 'center' }}>
                                        No feedback available
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                )}

                {selectedFeedback && (
                    <Modal show={showModal} onHide={handleCloseModal} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Feedback Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="feedback-details">
                                <p><strong>ID:</strong> <span>{selectedFeedback.id}</span></p>
                                <p><strong>Username:</strong> <span>{selectedFeedback.username}</span></p>
                                <p><strong>Feedback:</strong> <span>{selectedFeedback.feedback}</span></p>
                                <p><strong>Rating:</strong> <span className={getRatingClass(selectedFeedback.rating)}>{selectedFeedback.rating}</span></p>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseModal}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </div>
        </main>
    );
};

export default ViewFeedbacks;
