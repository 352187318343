import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import NavigationBar from './NavigationBar';
import axios from 'axios';
import { useTheme } from './ThemeContext';
import translations from './translate.js';
import './styles/1authForm.scss';

function LoginPage({ onLogin }) {
    const navigate = useNavigate();
    const language = localStorage.getItem('language') || 'lv'; 
    const text = translations[language]; 

    const { theme } = useTheme();
    
    const [formData, setFormData] = useState({
        username: '',
        password: '',
    });

    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const selectedLanguage = localStorage.getItem('language') || 'lv'; 
    
        try {
            const response = await axios.post(
                '/auth/login',
                formData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'language': selectedLanguage, 
                    },
                }
            );
    
            if (response.status === 200) {
                onLogin(); 
                navigate('/profile'); 
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                setErrorMessage(error.response.data.message);
            } else if (error.response) {
                setErrorMessage(text.loginErrorInvalidDetails); 
            } else {
                setErrorMessage(text.loginErrorConnection); 
            }
        }
    };
    

    return (
        <div data-theme={theme}>
        <div>
                <NavigationBar />
                <div className="container-fluid" id="form-container">
                    <div className="card">
                        <div className="card-body">
                            <h2 className="text-uppercase text-center" id="login-title">{text.logins}</h2>
                            <form onSubmit={handleSubmit}>
                                <div className="form-outline">
                                    <label className="form-label">{text.usernameLabel}</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        name="username"
                                        value={formData.username}
                                        onChange={handleChange} 
                                    />
                                </div>
                                <div className="form-outline">
                                    <label className="form-label">{text.password}</label>
                                    <input 
                                        type="password" 
                                        className="form-control" 
                                        name="password"
                                        value={formData.password}
                                        onChange={handleChange} 
                                    />
                                </div>
                                <div className="d-flex justify-content-center" id="btn-container">
                                    <button type="submit" className="btn" id="btn-form-submit">{text.logins}</button>
                                </div>
                                {errorMessage && (
                                    <div className="error-message text-center mt-3">
                                        {errorMessage}
                                    </div>
                                )}
                                <p className="text-center" id="footer-text">
                                    {text.noAccount}{' '}
                                    <Link to="/signup" className="nav-link">
                                        <u>{text.signUpHere}</u>
                                    </Link>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
        </div>
        </div>
    );
}

export default LoginPage;