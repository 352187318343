import React, { useEffect, useState } from 'react';
import { Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import NavigationBar from './NavigationBar';
import './styles/1home.scss';
import { useTheme } from './ThemeContext';
import translations from './translate.js';

function HomePage() {
    const [isLoggedIn, setIsLoggedIn] = useState(null);
    const { theme } = useTheme();

    useEffect(() => {
        const fetchAuthStatus = async () => {
            const res = await fetch('/api/auth/status');
            const data = await res.json();
            setIsLoggedIn(data.isLoggedIn);
        };

        fetchAuthStatus();
    }, []);

    if (isLoggedIn === null) {
        // return <div>Loading...</div>;
    }

    const language = localStorage.getItem('language') || 'lv'; 
    const text = translations[language]; 

    return (
            <div data-theme={theme}>
            <div>
                <NavigationBar />
                <div id="home-div">
                    <div className="main-page">
                        <Col xs={12} className="left-column">
                            <div className="text-container" id="main-text1">
                                <p>{text.saveTime}</p>
                            </div>
                            <div className="text-container" id="main-text2">
                                <p>{text.receiveDeals}</p>
                            </div>
                            <div className="text-container" id="main-text3">
                                <p>{text.customizeFilters}</p>
                            </div>
                            <div className="button-container">
                                <Button
                                    variant="outline-light"
                                    id="main-button"
                                    as={Link}
                                    to={isLoggedIn ? '/filters' : '/signup'}
                                >
                                    {isLoggedIn ? text.tryNow : text.signUp}
                                    <span className="custom-icon">
                                        <svg
                                            className="button-icon"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                        >
                                            <line
                                                x1="7"
                                                y1="17"
                                                x2="17"
                                                y2="7"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                            />
                                            <polyline
                                                points="7 7 17 7 17 17"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                            />
                                        </svg>
                                    </span>
                                </Button>
                            </div>
                        </Col>
                    </div>
                </div>
            </div>
            </div>
    );
}

export default HomePage;
