import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import translations from './translate.js'; 
import { useTheme } from './ThemeContext';
import './styles/1switch.scss';

const PageSwitcher = () => {
    const location = useLocation();
    const language = localStorage.getItem('language') || 'lv'; // Current language
    const text = translations[language]; // Translation data
    const { theme } = useTheme();

    // Determine which page is active
    const isFiltersActive = location.pathname === '/filters';
    // const isMotorcyclesActive = location.pathname === '/motorcycles';
    // const isSparePartsActive = location.pathname === '/spare-parts';

    return (
              <div data-theme={theme}>
        <div className="page-switch-buttons" style={{ marginBottom: '20px' }}>
            <Link to="/filters">
                <Button className={isFiltersActive ? 'active-button' : 'inactive-button'}>
                    {text.cars}
                </Button>
            </Link>
            {/* Uncomment and add translations if needed
            <Link to="/motorcycles">
                <Button className={isMotorcyclesActive ? 'active-button' : 'inactive-button'}>
                    {text.motorcycles}
                </Button>
            </Link>
            <Link to="/spare-parts">
                <Button 
                    className={isSparePartsActive ? 'active-button' : 'inactive-button'} 
                    disabled={isSparePartsDisabled} // Disabled based on state
                >
                    {text.spareParts}
                </Button>
            </Link> 
            */}
        </div>
        </div>
    );
};

export default PageSwitcher;
