// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'; 
import HomePage from './HomePage';
import FiltersPage from './FiltersPage';
import LoginPage from './LoginPage';
import TelegramVerifyPage from './TelegramVerifyPage';
import SignUpPage from './SignUpPage';
import ProfilePage from './ProfilePage';
import AdminPage from './AdminPage';
import AdminConsole from './AdminConsole';
import ProductsPage from './ProductsPage';
// import SessionExpiredModal from './SessionExpiredModal';
import FeedbackForm from './FeedbackForm';
import ViewFeedbacks from './ViewFeedbacks';
import MotorcyclesPage from './MotorcyclesPage'; 
import SparePartsPage from './SparePartsPage'; 
import { ThemeProvider } from './ThemeContext';

function App() {
  // const [isSessionExpired, setIsSessionExpired] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');  // Add username state

//userName pārbaude
  useEffect(() => {
    // Fetch login status from server
    const checkLoginStatus = async () => {
      const res = await fetch('/api/auth/status');
      const data = await res.json();
      setIsLoggedIn(data.isLoggedIn);
      setUsername(data.username || '');
    };
    checkLoginStatus();
  }, []);

  // useEffect(() => {
  //   let sessionTimeout;

  //   if (isLoggedIn) {
  //     // Set timeout for session expiration
  //     sessionTimeout = setTimeout(() => {
  //       setIsSessionExpired(true); // Show the session expired modal
  //     }, 3600000); // 30 seconds 30000

  //     // Clean up the timeout on unmount or if logged out
  //     return () => clearTimeout(sessionTimeout);
  //   }
  // }, [isLoggedIn]);

  // const handleCloseModal = () => {
  //   setIsSessionExpired(false);
  //   setIsLoggedIn(false);
  //   window.location.href = '/login'; // Redirect to login page
  // };

  const handleLogin = () => {
    setIsLoggedIn(true); // Set user as logged in
  };

  const handleLogout = () => {
    setIsLoggedIn(false); // Set user as logged out
    window.location.href = '/login'; // Redirect to login page
  };

  return (
    <ThemeProvider>
    <BrowserRouter>
      {/* {isSessionExpired && <SessionExpiredModal onClose={handleCloseModal} />} */}
      <Routes>
        <Route path='/api/filters/get' element={<Navigate to="/filters" />} /> 
        <Route path='/' element={<HomePage />} />
        <Route path='/filters' element={<FiltersPage />} />
        <Route path='/products' element={<ProductsPage />} />
        <Route path='/feedback' element={<FeedbackForm isLoggedIn={isLoggedIn} username={username} />} />
        <Route path='/login' element={<LoginPage onLogin={handleLogin} />} />
        <Route path="/verify/telegram" element={<TelegramVerifyPage />} />
        <Route path='/signup' element={<SignUpPage />} />
        <Route path='/profile' element={<ProfilePage onLogout={handleLogout} />} />
        <Route path='/admin' element={<AdminPage />} />
        <Route path='/admin/console' element={<AdminConsole />} />
        <Route path="/admin/feedbacks" element={<ViewFeedbacks />} />
        <Route path='/motorcycles' element={<MotorcyclesPage />} />
        <Route path='/spare-parts' element={<SparePartsPage />} />
      </Routes> 
    </BrowserRouter>
    </ThemeProvider>
  );
} 

export default App;
