const translations = {
    lv: {
        saveTime: "Ietaupiet savu laiku",
        receiveDeals: "Saņemiet savus vēlamus piedāvājumus uzreiz, tiklīdz tie parādās.",
        customizeFilters: "Pielāgojiet savus filtrus, saņemiet personalizētus piedāvājumus un esiet pirmais, kas tos izmanto.",
        tryNow: "Izmēģināt",
        addBtn: "Pievienot",
        details: "Detalizētie filtri",
        signUp: "Reģistrēties",
        productScheme: "Produktu shēma",
        feedback: "Atsauksmes",
        products: "Meklētājs",
        account: "Konts",
        language: "Valoda",
        profile: "Profils",
        signOut: "Iziet",
        understood: "Sapratu",
        login: "Ienākt",
        product: "Transports",
        shareFeedback: "Dalieties savā atsauksmē",
        feedbackIntro: "Jūsu atsauksmes palīdz mums uzlabot. Mēs labprāt dzirdētu jūsu viedokli!",
        rateExperience: "Novērtējiet savu pieredzi",
        enterEmail: "Ievadiet savu e-pastu",
        emailPlaceholder: "jusu.epasts@piemers.com",
        leaveComment: "Atstājiet komentāru",
        feedbackPlaceholder: "Pastāstiet par savu pieredzi...",
        submitFeedback: "Iesniegt atsauksmi",
        thankYouFeedback: "Paldies par jūsu atsauksmi!",
        successMessage: "Mēs novērtējam, ka veltījāt laiku, lai dalītos ar savu viedokli. Jūsu atsauksmes palīdz mums uzlabot mūsu pakalpojumus.",
        close: "Aizvērt",
        logins: "Ienākt",
        email: "E-pasts",
        password: "Parole",
        noAccount: "Vai jums nav konta?",
        signUpHere: "Reģistrējieties šeit",
        changePassword: "Mainīt paroli",
        changeEmail: "Mainīt lietotājvārdu",
        changeTelegram: "Mainīt Telegram kontu",
        currentPlan: "Pašreizējais plāns",
        activeFilters: "Aktīvie filtri:",
        userPlan: "Lietotāja plāns nodrošina ierobežotu piekļuvi bez papildu funkcijām.",
        basicPlan: "Basic plāns ļauj izveidot un izmantot līdz 5 SS filtriem.",
        advancedPlan: "Advanced plāns piedāvā iespēju pārvaldīt līdz 10 SS filtriem.",
        adminPlan: "Adminiem ir neierobežota piekļuve visām funkcijām un iespējām.",
        cars: "Automašīnas",
        motorcycles: "Motocikli",
        spareParts: "Rezerves daļas",
        verifyTelegramTitle: "Verificējiet savu Telegram kontu",
        step1: "Lejupielādējiet un instalējiet",
        telegramApp: "Telegram aplikāciju",
        or: "vai apmeklējiet",
        telegramWeb: "Telegram Web",
        step2: "Piesakieties savā Telegram kontā.",
        step3: "Meklēšanas joslā atrodiet",
        step4: "Ierakstiet komandu",
        step4End: "čatā un jūs saņemsiet verifikācijas kodu. Ievadiet šo kodu zemāk.",
        inputCodePlaceholder: "Kods...",
        verifying: "Verificē...",
        verify: "Verificēt",
        successModalTitle: "Verifikācija veiksmīga",
        feedbackModalTitle: "Nepieciešams pieslēgties",
        successModalBody: "Verifikācija tika veiksmīgi pabeigta! Drīz jūs tiksiet novirzīts uz pieteikšanās lapu.",
        feedbackModalBody: "Lai iesniegtu atsauksmi, jums ir jābūt pieslēgtam savam profilam.",
        accessRestricted: "Filtru pievienošana ierobežota",
        upgradePlan: "Lūdzu, jauniniet savu plānu, lai pievienotu filtrus.",
        addNewFilter: "Pievienot jaunu filtru",
        inactiveFilters: "Neaktīvie filtri",
        activeFilterLimitReached: "Sasniegts aktīvo filtru limits",
        upgradeModalBody: "Jūs esat sasniedzis maksimālo aktīvo filtru limitu savam plānam.",
        cancel: "Atcelt",
        upgrade: "Jaunināt",
        //filtriem mašīnas
        filterNameLabel: "Filtra nosaukums:",
        checkupAnyLabel: "Jebkurš",
        filterNamePlaceholder: "Mans pielāgotais filtra nosaukums",
        filterNameTooltip: "Piešķiriet filtra grupai nosaukumu, lai to vēlāk būtu viegli identificēt. Piemēram, jūs varat to nosaukt par 'Ģimenes automašīnas' vai 'SUV'.",
        carBrandLabel: "Automašīnas zīmols:",
        carBrandTooltip: "Izvēlieties automašīnas zīmolu, kas jūs interesē, piemēram, Toyota, BMW vai Ford.",
        carModelLabel: "Automašīnas modelis:",
        carModelTooltip: "Izvēlieties konkrētu automašīnas modeli, piemēram, 'Corolla' Toyota vai 'Civic' Honda.",
        priceLabel: "Cena:",
        priceTooltip: "Iestatiet meklētās cenas diapazonu. Lauks 'No' ir minimālā cena, un lauks 'Līdz' ir maksimālā cena, kuru esat gatavs maksāt.",
        priceFrom: "Cena no",
        priceTo: "Cena līdz",
        yearLabel: "Gads:",
        yearTooltip: "Izvēlieties automašīnas gadu diapazonu. Lauks 'No' ir senākais pieņemamais gads, un lauks 'Līdz' ir jaunākais pieņemamais gads.",
        yearFrom: "Gads no",
        yearTo: "Gads līdz",
        engineTypeLabel: "Dzinēja veids:",
        engineTypeTooltip: "Izvēlieties automašīnas dzinēja veidu, piemēram, dīzeļa vai elektrisko.",
        engineVolumeLabel: "Dzinēja tilpums:",
        engineVolumeTooltip: "Ievadiet dzinēja tilpuma diapazonu (mērvienība — litri). Lauks 'No' ir mazākais dzinēja izmērs, un lauks 'Līdz' ir lielākais. Piemēram, no 1.5L līdz 3.0L.",
        volumeFrom: "Dzinēja tilpums no",
        volumeTo: "Dzinēja tilpums līdz",
        gearboxLabel: "Ātrumkārba:",
        gearboxTooltip: "Izvēlieties ātrumkārbas (transmisijas) veidu, piemēram, manuālo vai automātisko.",
        bodyTypeLabel: "Virsbūves tips:",
        bodyTypeTooltip: "Izvēlieties automašīnas virsbūves stilu, piemēram, sedans, apvidus auto vai hečbeks.",
        colorLabel: "Krāsa:",
        colorTooltip: "Izvēlieties automašīnas krāsu, kuru meklējat, piemēram, melnu, baltu vai sarkanu.",
        areaLabel: "Atrašanās vieta:",
        areaTooltip: "Izvēlieties atrašanās vietu, kur vēlaties meklēt automašīnas, piemēram, pilsētu vai reģionu.",
        dealTypeLabel: "Darījuma veids:",
        dealTypeTooltip: "Izvēlieties darījuma veidu, kas jūs interesē, piemēram, automašīnas pirkšana, noma vai īre.",
        runDistanceLabel: "Nobraukums:",
        runDistanceTooltip: "Norādiet minimālo un maksimālo kilometru nobraukumu automašīnai.",
        runDistanceFrom: "Nobraukums no",
        runDistanceTo: "Nobraukums līdz",
        checkupLabel: "Tehniskā apskate derīga līdz:",
        checkupTooltip: "Izvēlieties jaunāko derīgo tehniskās apskates datumu. Izvēloties 'Nav preferenču', tiks rādītas automašīnas ar vai bez derīgas apskates.",
        noPreferenceOption: "Nav preferenču",
        saveButton: "Saglabāt",
        savingText: "Saglabā...",
        deleteButton: "Dzēst",
        deleteConfirmationTitle: "Dzēšanas apstiprinājums",
        deleteConfirmationBody: "Vai tiešām vēlaties dzēst <b>{filterName}</b> filtru?",
        cancelButton: "Atcelt",
        confirmButton: "Apstiprināt",
        deletingText: "Dzēš...",
        inactiveFilterAlert: "Jauns filtrs izveidots kā neaktīvs. Sasniegts maksimālais aktīvo filtru limits.",
        createAccountTitle: "Reģistrēties",
        emailLabel: "E-pasts",
        usernameLabel: "Lietotājvārds",
        passwordLabel: "Parole",
        confirmPasswordLabel: "Apstiprināt paroli",
        signUpButton: "Reģistrēties",
        registeringText: "Reģistrē...",
        loginPrompt: "Vai jums jau ir konts?",
        loginLinkText: "Pieteikties šeit",
        // Kļūdu paziņojumi frontendā
        signUpErrorGeneric: "Reģistrācija neizdevās. Lūdzu, mēģiniet vēlreiz.", // Sign up kļūdu paziņojums
        telegramInvalidCode: "Nepareizs vai nederīgs kods. Lūdzu, mēģiniet vēlreiz.", // Telegram kļūdu paziņojums
        telegramGenericError: "Radās kļūda. Lūdzu, mēģiniet vēlreiz vēlāk.", 
        verifySuccess: "Verifikācija veiksmīga.",
        loginErrorInvalidDetails: "Neizdevās pieteikties. Lūdzu, pārbaudiet savus datus un mēģiniet vēlreiz.", // Login kļūdu paziņojums
        loginErrorConnection: "Savienojuma kļūda. Lūdzu, pārbaudiet savienojumu un mēģiniet vēlreiz.",
        // Sākas backend kļūdu pazīņojumi 
        filterNameEmpty: "Filtra nosaukums nevar būt tukšs.",
        priceNegative: "Cenas nevar būt negatīvas.",
        volumeNegative: "Dzinēja tilpums nevar būt negatīvs.",
        runDistanceNegative: "Nobraukums nevar būt negatīvs.",
        priceRangeInvalid: "Minimālā cena nevar pārsniegt maksimālo cenu.",
        volumeRangeInvalid: "Minimālais dzinēja tilpums nevar pārsniegt maksimālo.",
        runRangeInvalid: "Minimālais nobraukums nevar pārsniegt maksimālo.",
        volumeZero: "Dzinēja tilpums nevar būt 0.",
        runZero: "Nobraukums nevar būt 0.",
        uniqueFilterName: "Filtra nosaukumam jābūt unikālam.",
        unexpectedError: "Radās neparedzēta kļūda.",
        yearOutOfRange: "Gadam jābūt intervālā no 1901 līdz 2025.",
        // feedback
        feedbackSubmitError: "Neizdevās iesniegt atsauksmi. Lūdzu, mēģiniet vēlreiz.",
        feedbackSubmitGenericError: "Radās kļūda, iesniedzot atsauksmi.",
        // api.js backend
        userNotAuthenticated: "Lietotājs nav autentificēts.",
        invalidEmailFormat: "Nederīgs e-pasta formāts.",
        invalidRoleSpecified: "Norādīta nederīga loma.",
        internalServerError: "Iekšēja servera kļūda.",
        //auth.js
        emailAlreadyInUse: "E-pasts jau tiek izmantots.",
        validUsernameFormat: "Nederīgs Lietotājvārda formāts.",
        usernameAlreadyInUse: "Konts ar šo Lietotājvārdu jau eksistē.",
        invalidUsernameFormat: "Lietotājvārds var saturēt tikai burtus, ciparus, '_', '-' un tam jābūt no 4 līdz 12 simboliem garam.",
        inappropriateUsername: "Lietotājvārds satur neatbilstošus vārdus.",
        usernameRequired: "Lietotājvārds ir nepieciešams.",
        passwordTooShort: "Parolei jābūt vismaz 8 rakstzīmēm.",
        passwordTooShortLogin: "Parolei jābūt vismaz 6 rakstzīmēm.",
        passwordMismatch: "Paroles nesakrīt.",
        signupSuccess: "Reģistrācija veiksmīga.",
        emailRequired: "E-pasts ir nepieciešams.",
        invalidCredentials: "Nederīgi pieteikšanās dati.",
        loginFailed: "Pieteikšanās neizdevās.",
        loginSuccess: "Pieteikšanās veiksmīga.",
        sessionActive: "Sesija ir aktīva.",
        sessionExpired: "Sesija ir beigusies.",
        viewPlans: "Skatīt plānus",
        darkMode: "Tumšais",
        lightMode: "Gaišais",
        themeMode: "Režīms",
        loading: "Ielādē...",
    },
    en: {
        saveTime: "Save your time",
        receiveDeals: "Receive your desired deals instantly as soon as they appear.",
        customizeFilters: "Customize your filters, receive tailored deals, and be the first to take advantage.",
        tryNow: "Try Now",
        addBtn: "Add",
        details: "Detailed Filters",
        signUp: "Sign Up",
        productScheme: "Product Scheme",
        feedback: "Feedback",
        products: "Search engine",
        account: "Account",
        language: "Language",
        profile: "Profile",
        signOut: "Sign out",
        understood: "Understood",
        login: "Login",
        product: "Transport",
        shareFeedback: "Share your Feedback",
        feedbackIntro: "Your input helps us improve. We'd love to hear from you!",
        rateExperience: "Rate your experience",
        enterEmail: "Enter your email",
        emailPlaceholder: "youremail@example.com",
        leaveComment: "Leave a comment",
        feedbackPlaceholder: "Tell us about your experience...",
        submitFeedback: "Submit Feedback",
        thankYouFeedback: "Thank you for your feedback!",
        successMessage: "We appreciate you taking the time to share your thoughts. Your feedback helps us improve our services.",
        close: "Close",
        logins: "Login",
        email: "Email",
        password: "Password",
        noAccount: "Don't have an account?",
        signUpHere: "Sign Up here",
        changePassword: "Change Password",
        changeEmail: "Change Username",
        changeTelegram: "Change Telegram Account",
        currentPlan: "Current Plan",
        activeFilters: "Active Filters:",
        userPlan: "The User plan provides limited access, with no additional features or benefits.",
        basicPlan: "The Basic plan allows the creation and usage of up to 5 SS filters.",
        advancedPlan: "The Advanced plan offers the ability to create and manage up to 10 SS filters.",
        adminPlan: "Admins have unrestricted access to all features and functionalities.",
        cars: "Cars",
        motorcycles: "Motorcycles",
        spareParts: "Spare Parts",
        verifyTelegramTitle: "Verify your Telegram account",
        step1: "Download and install",
        telegramApp: "Telegram app",
        or: "or visit",
        telegramWeb: "Telegram Web",
        step2: "Sign in to your Telegram account.",
        step3: "In the search bar, look for",
        step4: "Type the command",
        step4End: "in the chat, and you'll receive a verification code. Enter that code below.",
        inputCodePlaceholder: "Code...",
        verifying: "Verifying...",
        verify: "Verify",
        successModalTitle: "Verification Successful",
        feedbackModalTitle: "It is necessary to connect",
        successModalBody: "Verification was successful! You will be redirected to the login page shortly.",
        feedbackModalBody: "You must be logged in to your profile to submit a review.",
        accessRestricted: "Adding filters restricted",
        upgradePlan: "Please upgrade your plan to add filters.",
        addNewFilter: "Add new filter",
        inactiveFilters: "Inactive filters",
        activeFilterLimitReached: "Active Filter Limit Reached",
        upgradeModalBody: "You have reached the maximum limit of active filters for your plan.",
        cancel: "Cancel",
        upgrade: "Upgrade",
        filterNameLabel: "Filter name:",
        checkupAnyLabel: "Any",
        filterNamePlaceholder: "My custom filter name",
        filterNameTooltip: "Give your filter group a name so you can easily identify it later. For example, you can name it 'Family Cars' or 'SUVs'.",
        carBrandLabel: "Car brand:",
        carBrandTooltip: "Choose the brand (make) of the car you're interested in, such as Toyota, BMW, or Ford.",
        carModelLabel: "Car Model:",
        carModelTooltip: "Select the specific model of the car you want, like 'Corolla' for Toyota or 'Civic' for Honda.",
        priceLabel: "Price:",
        priceTooltip: "Set the price range you're looking for. The 'From' field is the minimum price, and the 'To' field is the maximum price you're willing to pay.",
        priceFrom: "Price from",
        priceTo: "Price to",
        yearLabel: "Year:",
        yearTooltip: "Choose the year range of the car. The 'From' field is the oldest year you're okay with, and the 'To' field is the newest year.",
        yearFrom: "Year from",
        yearTo: "Year to",
        engineTypeLabel: "Engine Type:",
        engineTypeTooltip: "Select the type of engine the car uses, such as diesel or electric.",
        engineVolumeLabel: "Engine volume:",
        engineVolumeTooltip: "Enter the engine volume range (measured in liters). 'From' is the smallest engine size, and 'To' is the largest. For example, 1.5L to 3.0L.",
        volumeFrom: "Engine volume from",
        volumeTo: "Engine volume up to",
        gearboxLabel: "Gearbox:",
        gearboxTooltip: "Choose the type of gearbox (transmission) you prefer, such as manual or automatic.",
        bodyTypeLabel: "Body Type:",
        bodyTypeTooltip: "Select the body style of the car, like sedan, SUV, or hatchback.",
        colorLabel: "Color:",
        colorTooltip: "Pick the color of the car you're looking for, like black, white, or red.",
        areaLabel: "Area:",
        areaTooltip: "Select the location where you want to find cars, such as a city or region.",
        dealTypeLabel: "Deal type:",
        dealTypeTooltip: "Choose the type of deal you're interested in, such as buying, leasing, or renting the car.",
        runDistanceLabel: "Run Distance:",
        runDistanceTooltip: "Specify the minimum and maximum kilometers the car has run.",
        runDistanceFrom: "Mileage from",
        runDistanceTo: "Mileage up to",
        checkupLabel: "Checkup valid until:",
        checkupTooltip: "Select the latest valid checkup date. Choosing 'No Preference' will show cars with or without a valid checkup.",
        noPreferenceOption: "No Preference",
        saveButton: "Save",
        savingText: "Saving...",
        deleteButton: "Delete",
        deleteConfirmationTitle: "Delete Confirmation",
        deleteConfirmationBody: "Are you sure you want to delete <b>{filterName}</b> filter?",
        cancelButton: "Cancel",
        confirmButton: "Confirm",
        deletingText: "Deleting...",
        inactiveFilterAlert: "New filter created as inactive. Maximum active filter limit reached.",
        createAccountTitle: "Register",
        emailLabel: "Email",
        usernameLabel: "Username",
        passwordLabel: "Password",
        confirmPasswordLabel: "Confirm Password",
        signUpButton: "Sign Up",
        registeringText: "Registering...",
        loginPrompt: "Already have an account?",
        loginLinkText: "Login here",
        signUpErrorGeneric: "Registration failed. Please try again.", 
        telegramInvalidCode: "Invalid or expired code. Please try again.", 
        telegramGenericError: "An error occurred. Please try again later.",
        verifySuccess: "Verification successful.",
        loginErrorInvalidDetails: "Failed to login. Please check your details and try again.", 
        loginErrorConnection: "Connection error. Please check your connection and try again.", 
        // Sākas backend kļūdu pazīņojumi 
        filterNameEmpty: "Filter name cannot be empty.",
        priceNegative: "Prices cannot be negative.",
        volumeNegative: "Engine volume cannot be negative.",
        runDistanceNegative: "Run distances cannot be negative.",
        priceRangeInvalid: "Minimum price cannot exceed maximum price.",
        volumeRangeInvalid: "Minimum engine volume cannot exceed maximum volume.",
        runRangeInvalid: "Minimum run distance cannot exceed maximum distance.",
        volumeZero: "Engine volume cannot be 0.",
        runZero: "Run distance cannot be 0.",
        uniqueFilterName: "Filter name must be unique.",
        unexpectedError: "An unexpected error occurred.",
        yearOutOfRange: "The year must be between 1901 and 2025.",
        feedbackSubmitError: "Failed to submit feedback. Please try again.",
        feedbackSubmitGenericError: "An error occurred while submitting feedback.",
        userNotAuthenticated: "User not authenticated.",
        invalidEmailFormat: "Invalid email format.",
        invalidRoleSpecified: "Invalid role specified.",
        internalServerError: "Internal server error.",
        emailAlreadyInUse: "Email already in use.",
        validUsernameFormat: "Invalid Username Format.",
        usernameAlreadyInUse: "Account with this Username already exists.",
        invalidUsernameFormat: "Lietotājvārds var saturēt tikai burtus, ciparus, '_', '-' un tam jābūt no 4 līdz 12 simboliem garam.",
        inappropriateUsername: "Username contains inappropriate words.",
        usernameRequired: "Username is required.",
        passwordTooShort: "Password must be at least 8 characters long.",
        passwordTooShortLogin: "Password must be at least 6 characters.",
        passwordMismatch: "Passwords do not match.",
        signupSuccess: "Signup successful.",
        emailRequired: "Email is required.",
        invalidCredentials: "Invalid credentials.",
        loginFailed: "Login failed.",
        loginSuccess: "Login successful.",
        sessionActive: "Session is active.",
        sessionExpired: "Session has expired.",
        viewPlans: "View plans",
        darkMode: "Dark",
        lightMode: "Light",
        themeMode: "Mode",
        loading: "Loading...",
    },
    ru: {
        saveTime: "Сэкономьте свое время",
        receiveDeals: "Получайте желаемые предложения сразу после их появления.",
        customizeFilters: "Настройте свои фильтры, получайте персонализированные предложения и будьте первым, кто их использует.",
        tryNow: "Попробовать",
        addBtn: "Добавлять",
        details: "Подробные фильтры",
        signUp: "Зарегистрироваться",
        productScheme: "Схема продукта",
        feedback: "Отзывы",
        products: "Поисковая система",
        account: "Аккаунт",
        language: "Язык",
        profile: "Профиль",
        signOut: "Выйти",
        understood: "Понял",
        login: "Вход",
        product: "Транспорт",
        shareFeedback: "Поделитесь своим отзывом",
        feedbackIntro: "Ваши отзывы помогают нам стать лучше. Нам важно ваше мнение!",
        rateExperience: "Оцените ваш опыт",
        enterEmail: "Введите ваш email",
        emailPlaceholder: "ваш.почта@пример.com",
        leaveComment: "Оставьте комментарий",
        feedbackPlaceholder: "Расскажите о вашем опыте...",
        submitFeedback: "Отправить отзыв",
        thankYouFeedback: "Спасибо за ваш отзыв!",
        successMessage: "Мы ценим ваше время и ваше мнение. Ваш отзыв помогает нам улучшить наши услуги.",
        close: "Закрыть",
        logins: "Войти",
        email: "Электронная почта",
        password: "Пароль",
        noAccount: "У вас нет аккаунта?",
        signUpHere: "Зарегистрируйтесь здесь",
        changePassword: "Изменить пароль",
        changeEmail: "Изменить имя пользователя",
        changeTelegram: "Изменить аккаунт Telegram",
        currentPlan: "Текущий план",
        activeFilters: "Активные фильтры:",
        userPlan: "План пользователя предоставляет ограниченный доступ без дополнительных функций.",
        basicPlan: "Базовый план позволяет создать и использовать до 5 SS фильтров.",
        advancedPlan: "Расширенный план предоставляет возможность управлять до 10 SS фильтрами.",
        adminPlan: "Администраторы имеют неограниченный доступ ко всем функциям.",
        cars: "Машины",
        motorcycles: "Мотоциклы",
        spareParts: "Запчасти",
        verifyTelegramTitle: "Подтвердите свой аккаунт Telegram",
        step1: "Скачайте и установите",
        telegramApp: "приложение Telegram",
        or: "или посетите",
        telegramWeb: "Telegram Web",
        step2: "Войдите в свой аккаунт Telegram.",
        step3: "В строке поиска найдите",
        step4: "Введите команду",
        step4End: "в чате, и вы получите код подтверждения. Введите этот код ниже.",
        inputCodePlaceholder: "Kод...",
        verifying: "Проверка...",
        verify: "Подтвердить",
        successModalTitle: "Подтверждение успешно",
        feedbackModalTitle: "Необходимо подключить",
        successModalBody: "Подтверждение успешно завершено! Вы скоро будете перенаправлены на страницу входа.",
        feedbackModalBody: "Вы должны войти в свой профиль, чтобы оставить отзыв.",
        accessRestricted: "Добавление фильтров ограничено",
        upgradePlan: "Пожалуйста, обновите свой план, чтобы добавить фильтры.",
        addNewFilter: "Добавить новый фильтр",
        inactiveFilters: "Неактивные фильтры",
        activeFilterLimitReached: "Достигнут лимит активных фильтров",
        upgradeModalBody: "Вы достигли максимального лимита активных фильтров для вашего плана.",
        cancel: "Отмена",
        upgrade: "Обновить",
        filterNameLabel: "Название фильтра:",
        checkupAnyLabel: "Любой",
        filterNamePlaceholder: "Мое пользовательское имя фильтра",
        filterNameTooltip: "Дайте вашей группе фильтров название, чтобы вы могли легко её идентифицировать позже. Например, вы можете назвать её 'Семейные автомобили' или 'Внедорожники'.",
        carBrandLabel: "Марка автомобиля:",
        carBrandTooltip: "Выберите марку автомобиля, которая вас интересует, например, Toyota, BMW или Ford.",
        carModelLabel: "Модель автомобиля:",
        carModelTooltip: "Выберите конкретную модель автомобиля, например, 'Corolla' для Toyota или 'Civic' для Honda.",
        priceLabel: "Цена:",
        priceTooltip: "Установите диапазон цен, который вас интересует. Поле 'От' — минимальная цена, а поле 'До' — максимальная цена, которую вы готовы заплатить.",
        priceFrom: "Цена от",
        priceTo: "Цена до",
        yearLabel: "Год:",
        yearTooltip: "Выберите диапазон годов автомобиля. Поле 'От' — это самый старый год, который вас устраивает, а поле 'До' — самый новый год.",
        yearFrom: "Год от",
        yearTo: "Год до",
        engineTypeLabel: "Тип двигателя:",
        engineTypeTooltip: "Выберите тип двигателя автомобиля, например, дизельный или электрический.",
        engineVolumeLabel: "Объем двигателя:",
        engineVolumeTooltip: "Введите диапазон объема двигателя (измеряется в литрах). Поле 'От' — это минимальный размер двигателя, а поле 'До' — максимальный. Например, от 1.5L до 3.0L.",
        volumeFrom: "Объем двигателя от",
        volumeTo: "Объем двигателя до",
        gearboxLabel: "Коробка передач:",
        gearboxTooltip: "Выберите тип коробки передач (трансмиссии), например, механическую или автоматическую.",
        bodyTypeLabel: "Тип кузова:",
        bodyTypeTooltip: "Выберите стиль кузова автомобиля, например, седан, внедорожник или хэтчбек.",
        colorLabel: "Цвет:",
        colorTooltip: "Выберите цвет автомобиля, который вы ищете, например, черный, белый или красный.",
        areaLabel: "Местоположение:",
        areaTooltip: "Выберите местоположение, где вы хотите найти автомобили, например, город или регион.",
        dealTypeLabel: "Тип сделки:",
        dealTypeTooltip: "Выберите тип сделки, который вас интересует, например, покупка, аренда или лизинг автомобиля.",
        runDistanceLabel: "Пробег:",
        runDistanceTooltip: "Укажите минимальный и максимальный пробег автомобиля в километрах.",
        runDistanceFrom: "Пробег от",
        runDistanceTo: "Пробег до",
        checkupLabel: "Техосмотр действителен до:",
        checkupTooltip: "Выберите последнюю действительную дату техосмотра. Выбор 'Без предпочтений' покажет автомобили с действительным или недействительным техосмотром.",
        noPreferenceOption: "Без предпочтений",
        // buttons
        saveButton: "Сохранить",
        savingText: "Сохраняет...",
        deleteButton: "Удалить",
        deleteConfirmationTitle: "Подтверждение удаления",
        deleteConfirmationBody: "Вы уверены, что хотите удалить фильтр <b>{filterName}</b>?",
        cancelButton: "Отмена",
        confirmButton: "Подтвердить",
        deletingText: "Удаляет...",
        inactiveFilterAlert: "Новый фильтр создан как неактивный. Достигнут максимальный лимит активных фильтров.",
        createAccountTitle: "Создать аккаунт",
        emailLabel: "Электронная почта",
        usernameLabel: "Имя пользователя",
        passwordLabel: "Пароль",
        confirmPasswordLabel: "Подтвердите пароль",
        signUpButton: "Зарегистрироваться",
        registeringText: "Регистрация...",
        loginPrompt: "Уже есть аккаунт?",
        loginLinkText: "Войти здесь",
        signUpErrorGeneric: "Регистрация не удалась. Пожалуйста, попробуйте еще раз.", 
        telegramInvalidCode: "Неверный или истёкший код. Пожалуйста, попробуйте ещё раз.",
        telegramGenericError: "Произошла ошибка. Пожалуйста, попробуйте позже.", 
        verifySuccess: "Подтверждение успешно.",
        loginErrorInvalidDetails: "Не удалось войти. Пожалуйста, проверьте свои данные и попробуйте снова.", 
        loginErrorConnection: "Ошибка соединения. Пожалуйста, проверьте соединение и попробуйте снова.",
        // Sākas backend kļūdu pazīņojumi 
        filterNameEmpty: "Название фильтра не может быть пустым.",
        priceNegative: "Цены не могут быть отрицательными.",
        volumeNegative: "Объем двигателя не может быть отрицательным.",
        runDistanceNegative: "Пробег не может быть отрицательным.",
        priceRangeInvalid: "Минимальная цена не может превышать максимальную.",
        volumeRangeInvalid: "Минимальный объем двигателя не может превышать максимальный.",
        runRangeInvalid: "Минимальный пробег не может превышать максимальный.",
        volumeZero: "Объем двигателя не может быть 0.",
        runZero: "Пробег не может быть 0.",
        uniqueFilterName: "Название фильтра должно быть уникальным.",
        unexpectedError: "Произошла непредвиденная ошибка.",
        yearOutOfRange: "Год должен быть между 1901 и 2025.",
        feedbackSubmitError: "Не удалось отправить отзыв. Пожалуйста, попробуйте еще раз.",
        feedbackSubmitGenericError: "Произошла ошибка при отправке отзыва.",
        userNotAuthenticated: "Пользователь не авторизован.",
        invalidEmailFormat: "Недопустимый формат электронной почты.",
        invalidRoleSpecified: "Указана недопустимая роль.",
        internalServerError: "Внутренняя ошибка сервера.",
        emailAlreadyInUse: "Электронная почта уже используется.",
        validUsernameFormat: "Неверный формат имени пользователя.",
        usernameAlreadyInUse: "Учетная запись с таким именем пользователя уже существует.",
        invalidUsernameFormat: "Имя пользователя может содержать только буквы, цифры, '_', '-' и должно иметь длину от 4 до 12 символов.",
        inappropriateUsername: "Имя пользователя содержит недопустимые слова.",
        usernameRequired: "Требуется имя пользователя.",
        passwordTooShort: "Пароль должен быть не менее 8 символов.",
        passwordTooShortLogin: "Пароль должен быть не менее 6 символов.",
        passwordMismatch: "Пароли не совпадают.",
        signupSuccess: "Регистрация успешна.",
        emailRequired: "Требуется электронная почта.",
        invalidCredentials: "Неверные учетные данные.",
        loginFailed: "Ошибка входа.",
        loginSuccess: "Вход выполнен успешно.",
        sessionActive: "Сессия активна.",
        sessionExpired: "Сессия истекла.",
        viewPlans: "Тарифы",
        darkMode: "Темный",
        lightMode: "Светлый",
        themeMode: "Режим",
        loading: "Загрузка...",
    },
};

module.exports = translations;
