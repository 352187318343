import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './styles/1telegramVerify.scss';
import NavigationBar from './NavigationBar';
import { useTheme } from './ThemeContext';
import { Modal, Button } from 'react-bootstrap';
import qrCodeImage  from './img/telegramqr1.svg';
import translations from './translate.js'; 

const TelegramVerifyPage = () => {
    const [code, setCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false); 
    const navigate = useNavigate();

    const language = localStorage.getItem('language') || 'lv'; 
    const text = translations[language]; 

     const { theme } = useTheme();

    useEffect(() => {
        let timer;
        if (showModal) {
            
            timer = setTimeout(() => {
                navigate('/login');
            }, 10000);
        }
        return () => clearTimeout(timer); 
    }, [showModal, navigate]);

    const handleVerify = async (e) => {
        e.preventDefault(); 

        const selectedLanguage = localStorage.getItem('language') || 'lv';
        try {
            setIsLoading(true);
            const requestData = { code };
    
            const response = await axios.post('/verify/telegram', requestData, {
                headers: {
                    'Content-Type': 'application/json',
                    'language': selectedLanguage, 
                },
            });

            if (response.status === 201) {
                setErrorMessage('');
                setCode('');
                setShowModal(true);
            } else {
                setErrorMessage(response.data.message || text.telegramInvalidCode); 
            }
        } catch (error) {
            console.error('Error during verification:', error);
            if (error.response) {
                setErrorMessage(error.response.data.message || text.telegramGenericError); 
            } else {
                setErrorMessage(text.telegramGenericError); 
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleLoginRedirect = () => {
        navigate('/login');
    };

    return (
            <div data-theme={theme}>
        <div>
            <NavigationBar />
            <div className="container-fluid" id="telegram-verification-container">
            <div className="outer-border">
            <div className="middle-border">
                <div className="card">
                    <div className="card-body">
                        <h2 className="text-center" id="verify-telegram-title">
                            {text.verifyTelegramTitle}
                        </h2>

                        <div className="text-center my-3">
                        <a href="https://t.me/StalkerWindBOT" target="_blank" rel="noopener noreferrer">
                            <div className="qr-code-container">
                                <img
                                    src={qrCodeImage}
                                    alt="Telegram QR Code"
                                    className="qr-code"
                                />
                            </div>
                        </a>
                    </div>


                        <ol>
                            <li>
                                {text.step1}{' '}
                                <a href="https://telegram.org">{text.telegramApp}</a>{' '}
                                {text.or}{' '}
                                <a href="https://web.telegram.org">{text.telegramWeb}</a>.
                            </li>
                            <li>{text.step2}</li>
                            <li>
                                {text.step3}{' '}
                                <strong>@StalkerWindBOT</strong>.
                            </li>
                            <li>
                                {text.step4}{' '}
                                <span>/verify</span>{' '}
                                {text.step4End}.
                            </li>
                        </ol>

                        <form onSubmit={handleVerify}>
                            <div className="form-outline">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="verificationCode"
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                    placeholder={text.inputCodePlaceholder}
                                    disabled={isLoading}
                                />
                            </div>

                            <div className="d-flex justify-content-center" id="btn-container">
                                <button type="submit" className="btn" id="btn-form-submit" disabled={isLoading}>
                                    {isLoading ? text.verifying : text.verify}
                                </button>
                            </div>

                            {errorMessage && (
                                <div className="error-message text-center mt-3">
                                    {errorMessage}
                                </div>
                            )}
                        </form>

                        {/* Success Modal */}
                        <Modal show={showModal} onHide={handleLoginRedirect} centered>
                            <Modal.Header closeButton>
                                <Modal.Title>{text.feedbackModalTitle}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>{text.feedbackModalBody}</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" onClick={handleLoginRedirect}>
                                    {text.login}
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </div>
        </div>
    );
};

export default TelegramVerifyPage;
