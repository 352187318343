import React, { useState, useEffect } from 'react';
import { Container, Button, Accordion, Alert, Modal } from 'react-bootstrap';
import FiltersForm from './FiltersForm';
import NavigationBar from './NavigationBar';
import PageSwitcher from './PageSwitcher';
import axios from 'axios';
import translations from './translate.js'; // Import translations
import './styles/1filters.scss';
import { useTheme } from './ThemeContext';
import pluss from './img/pluss.svg';

function FiltersPage() {
  const [filterForms, setFilterForms] = useState([]);
  const [filters, setFilters] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [inactiveFilters, setInactiveFilters] = useState([]);
  const [role, setRole] = useState('');
  const [formCount, setFormCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const { theme } = useTheme();

  const language = localStorage.getItem('language') || 'lv'; // Current language
  const text = translations[language]; // Translation data

  // Modal handlers
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  // Fetch filters and update active/inactive lists
  const fetchFilters = async () => {
    try {
      const response = await axios.get('/filters/get/filters');
      const filtersData = response.data;

      const formattedFilters = Object.keys(filtersData).map(filterId => ({
        filterId,
        ...filtersData[filterId][0]
      }));

      const activeFilters = formattedFilters.filter(filter => filter.active === 1);
      const inactiveFilters = formattedFilters.filter(filter => filter.active === 0);

      setFilters(activeFilters);
      setInactiveFilters(inactiveFilters);

      setFormCount(activeFilters.length);
    } catch (error) {
      console.error('Error fetching filters:', error);
    }
  };

  useEffect(() => {
    const fetchAuthStatus = async () => {
      try {
        const response = await axios.get('/api/auth/status', { withCredentials: true });
        setIsLoggedIn(response.data.isLoggedIn);
      } catch (error) {
        console.error('Error fetching authentication status:', error);
      }
    };
  
    fetchAuthStatus();
  }, []);
  
  useEffect(() => {
    fetchFilters();
    const fetchFormData = async () => {
      try {
        const count = await axios.get('/filters/get/count');
        setFormCount(count.data.count);
      } catch (error) {
        console.error('Error fetching filter count:', error);
      }
    };

    const getUser = async () => {
      try {
        const response = await axios.get('/api/get/user', { withCredentials: true });
        setRole(response.data.role);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchFormData();
    getUser();
  }, []);

  const getFilterLimit = () => {
    if (role === 'admin') return 20;
    if (role === 'advanced') return 10;
    if (role === 'basic') return 5;
    return 0;
  };

  const handleAddFilterClick = async () => {
    const filterLimit = getFilterLimit();

    const response = await axios.get('/filters/get/count');
    const currentActiveCount = response.data.count;

    const newFilterActive = currentActiveCount < filterLimit ? 1 : 0;

    const newFilter = {
      id: Date.now(),
      filters: { active: newFilterActive },
    };

    setFilterForms([...filterForms, newFilter]);
    if (newFilterActive === 1) {
      setFormCount(formCount + 1);
    }
  };

  // Handle status change, show modal if exceeding limit
  const handleStatusChange = async (filterId, newStatus) => {
    const filterLimit = getFilterLimit();

    if (newStatus === 1 && formCount >= filterLimit) {
      handleShowModal();
      return;
    }

    try {
      const response = await axios.put(`/filters/update/active/${filterId}`, { active: newStatus }, { withCredentials: true });
      if (response.status === 200) {
        await fetchFilters();
      } else {
        console.error('Unexpected response status:', response.status);
        alert('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      console.error('Error updating filter status:', error);
      alert('An error occurred while updating the filter. Please check the console for more details.');
    }
  };

  return (
      <div data-theme={theme}>
        <div>
          <NavigationBar />
          <Container fluid id='filter-page-container'>
            <PageSwitcher />
            {(role !== 'basic' && role !== 'admin' && role !== 'advanced') && (
              <Alert id='restricted-alert'>
                <Alert.Heading>{text.accessRestricted}</Alert.Heading>
                <hr />
                <p>{text.upgradePlan}</p>
              </Alert>
            )}

            <Accordion>
              {filters.map(filter => (
                <FiltersForm
                  key={filter.filterId}
                  filterData={filter}
                  onStatusChange={handleStatusChange}
                />
              ))}
              {filterForms.map(form => (
                <FiltersForm key={form.id} filterData={form.filters} />
              ))}
            </Accordion>

            {(role === 'basic' || role === 'advanced' || role === 'admin') && (
              <div className='btn-new-container'>
              <Button 
                onClick={handleAddFilterClick} 
                className="d-flex align-items-center justify-content-center"
                style={{ height: '40px', padding: '0 12px' }} 
              >
                <img 
                  src={pluss} 
                  alt="Add Filter" 
                  style={{ width: '20px', height: '20px', marginRight: '5px' }} 
                />
                <span className="add-new-filter">{text.addNewFilter}</span>
              </Button>
            </div>            
            )}

            {isLoggedIn && (
              <>
                <div className="section-title">{text.inactiveFilters}</div>
                <Accordion>
                  {inactiveFilters.map(filter => (
                    <FiltersForm
                      key={filter.filterId}
                      filterData={filter}
                      onStatusChange={handleStatusChange}
                      className="inactive-filter"
                    />
                  ))}
                </Accordion>
              </>
            )}

            <Modal show={showModal} onHide={handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>{text.activeFilterLimitReached}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {text.upgradeModalBody}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                  {text.cancel}
                </Button>
                <Button variant="primary" disabled>
                  {text.upgrade}
                </Button>
              </Modal.Footer>
            </Modal>
          </Container>
        </div>
      </div>
  );
}

export default FiltersPage;
