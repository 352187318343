import React, { useState, useEffect, useCallback, useRef } from 'react';
import NavigationBar from './NavigationBar';
import { Container, Card, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { InfoCircle, Gear, Lock, Envelope, Telegram } from 'react-bootstrap-icons';
import translations from './translate.js'; 
import { useTheme} from './ThemeContext';
import adminsvg from './img/admin.svg';
import product from './img/product.svg';
import logout from './img/logout.svg';
import dimants from './img/dimants.svg';
import pogadi from './img/pogadi.svg';
import adminDark from './img/admin02.svg';
import productDark from './img/product02.svg';
import logoutDark from './img/logout02.svg';
import './styles/1profile.scss';
// ProfilePage.js
function ProfilePage() {
    const [username, setUsername] = useState('');
    const [role, setRole] = useState('');
    const [filterCount, setFilterCount] = useState(0);
    const [maxFilters, setMaxFilters] = useState(0);
    const [showMenu, setShowMenu] = useState(false);
    const navigate = useNavigate();
    const menuRef = useRef(null); // Atsauce uz izvēlni

    const language = localStorage.getItem('language') || 'lv'; // Valodas iestatījums
    const text = translations[language]; // Tulkotie teksti

    const { theme } = useTheme();

    const getUser = useCallback(async () => {
        try {
            const response = await axios.get('/api/get/user', { withCredentials: true });
            if (response.status === 200) {
                const userData = response.data;
                setUsername(userData.username);
                setRole(userData.role);
                setMaxFilters(getMaxFilters(userData.role));
            }
        } catch (err) {
            console.error(err);
            alert('Kļūda: ' + (err.response?.data.message || 'Nezināma kļūda. Lūdzu, mēģiniet vēlreiz.'));
        }
    }, []);

    const getFilterCount = useCallback(async () => {
        try {
            const [carResponse, motorcycleResponse] = await Promise.all([
                axios.get('/filters/get/count', { withCredentials: true }),
                axios.get('/motorcycles/get/count', { withCredentials: true }),
            ]);

            if (carResponse.status === 200 && motorcycleResponse.status === 200) {
                setFilterCount(carResponse.data.count + motorcycleResponse.data.count);
            }
        } catch (err) {
            console.error(err);
            alert('Error fetching combined filter counts.');
        }
    }, []);

    const checkAuthStatus = useCallback(async () => {
        try {
            const response = await axios.get('/api/auth/status', { withCredentials: true });
            if (!response.data.isLoggedIn) {
                navigate('/login');
            }
        } catch (err) {
            console.error('Error checking auth status:', err);
            navigate('/login');
        }
    }, [navigate]);

    useEffect(() => {
        checkAuthStatus();
        getUser();
        getFilterCount();
    }, [checkAuthStatus, getUser, getFilterCount]);

    const getMaxFilters = (role) => {
        switch (role) {
            case 'basic':
                return 5;
            case 'advanced':
                return 10;
            case 'admin':
                return 20;
            default:
                return 0;
        }
    };

    const toggleMenu = () => setShowMenu(!showMenu);

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setShowMenu(false);
        }
    };

    useEffect(() => {
        if (showMenu) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [showMenu]);

    return (
            <div data-theme={theme}>
            <div>
                <NavigationBar />
                <Container id="profile-container">
                <div className="outer2-border">
                <div className="middle2-border">
                    <Card>
                        <Card.Body>
                            <div className="profile-title">
                                {text.profile}
                                <div className="settings-icon-container" ref={menuRef}>
                                    <Gear className="settings-icon" onClick={toggleMenu} />
                                    {showMenu && (
                                        <div className="settings-dropdown">
                                            <div className="dropdown-item disabled-button">
                                                <Lock className="icon" />
                                                {text.changePassword}
                                            </div>
                                            <div className="dropdown-item disabled-button">
                                                <Envelope className="icon" />
                                                {text.changeEmail}
                                            </div>
                                            <div className="dropdown-item disabled-button">
                                                <Telegram className="icon" />
                                                {text.changeTelegram}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <Card.Text className="profile-email">{username}</Card.Text>
                            <div className="plan-container">
                            <div className="plan-info">
                                <Card.Title className="plan-title">{text.currentPlan}</Card.Title>
                                <Card.Text className="plan-role">{role}</Card.Text>
                            </div>
                            <Button className="gradient-btn" style={{ backgroundImage: `url(${pogadi})` }}>
                                <img src={dimants} alt="Diamond" className="btn-icon" /> {text.viewPlans}
                            </Button>
                        </div>

                            <div className="filter-container">
                                <Card.Title className="filter-title">{text.activeFilters}</Card.Title>
                                <Card.Text className="filter-count">{`${filterCount}/${maxFilters}`}</Card.Text>
                            </div>

                            <div className="buttons-section">
                                <div className="full-width-btn" onClick={() => (window.location.href = '/filters')}>
                                    <img src={theme === 'dark' ? productDark : product} alt="Product" className="icon" />
                                    {text.products}
                                    <span className="arrow">→</span>
                                </div>
                                {role === 'admin' && (
                                    <div className="full-width-btn" onClick={() => (window.location.href = '/admin')}>
                                        <img src={theme === 'dark' ? adminDark : adminsvg} alt="Admin" className="icon" />
                                        Admin
                                        <span className="arrow">→</span>
                                    </div>
                                )}
                                <div className="full-width-btn" onClick={() => (window.location.href = '/api/auth/signout')}>
                                    <img src={theme === 'dark' ? logoutDark : logout} alt="Logout" className="icon" />
                                    {text.signOut}
                                    <span className="arrow">→</span>
                                </div>
                            </div>

                        </Card.Body>
                    </Card>
                    </div>
                    </div>
                </Container>
            </div>
            </div>
    );
}

export default ProfilePage;