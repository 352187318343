import React, { useState, useEffect } from 'react';
import NavigationBar from './NavigationBar'; 
import { Modal, Button } from 'react-bootstrap';
import './styles/1feedback.scss';
import translations from './translate.js';
import { useTheme} from './ThemeContext';

function FeedbackForm({ isLoggedIn }) {
    const [rating, setRating] = useState(3); 
    const [feedback, setFeedback] = useState('');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const { theme } = useTheme();

    const language = localStorage.getItem('language') || 'lv'; 
    const text = translations[language]; 

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(''); 
    
        const feedbackData = {
            rating,
            feedback,
        };
    
        try {
            const response = await fetch('/feedback/submit', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(feedbackData),
            });
    
            const responseData = await response.json();
    
            if (!response.ok) {
                setError(responseData.message || text.feedbackSubmitError);
            } else {
                setSuccessMessage(true);
            }
        } catch (error) {
            console.error('Error submitting feedback:', error);
            setError(text.feedbackSubmitGenericError);
        }
    };
    

    const handleCloseModal = () => {
        // Aizver modālo logu
        setShowModal(false);
    };

    const handleLoginRedirect = () => {
        // Pārvieto lietotāju uz Login lapu
        window.location.href = '/login';
    };

    useEffect(() => {
        if (isLoggedIn === false) {  // Tikai ja skaidri zinām, ka lietotājs nav ielogojies
            setShowModal(true);
        }
    }, [isLoggedIn]);

    return (
             <div data-theme={theme}>
        <div>
            <NavigationBar isLoggedIn={isLoggedIn} />

            {isLoggedIn === false && (
                <Modal show={showModal} onHide={handleCloseModal} centered>
                    <Modal.Header>
                        <Modal.Title>{text.feedbackModalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{text.feedbackModalBody}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            {text.understood}
                        </Button>
                        <Button variant="primary" onClick={handleLoginRedirect}>
                            {text.login}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            <div className="outer3-border">
            <div className="middle3-border">
            <div className="feedback-page">
                {!successMessage && (
                    <>
                        <h2>{text.shareFeedback}</h2>
                        <p>{text.feedbackIntro}</p>
                    </>
                )}

                {successMessage ? (
                    <div className="success-message">
                        <h2>{text.thankYouFeedback}</h2>
                        <p>{text.successMessage}</p>
                        <button onClick={() => window.location.href = '/'}>{text.close}</button>
                    </div>
                ) : (
                    <form onSubmit={handleSubmit}>
                        <div className="rating-system">
                            <label>{text.rateExperience}</label>
                            <div className="star-rating">
                                {[...Array(5)].map((_, index) => (
                                    <div className="star-container" key={index}>
                                        <span
                                            className={`star ${rating >= index + 1 ? 'selected' : ''}`}
                                            onClick={() => setRating(index + 1)}
                                        >
                                            ★
                                        </span>
                                        <span className="tooltip">{`${index + 1}/5`}</span>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="feedback-input">
                            <label>{text.leaveComment}</label>
                            <textarea
                                value={feedback}
                                onChange={(e) => setFeedback(e.target.value)}
                                placeholder={text.feedbackPlaceholder}
                            />
                        </div>

                        {error && <p className="error-message">{error}</p>}

                        {isLoggedIn && (
                            <button type="submit" className="submit-button">{text.submitFeedback}</button>
                        )}
                    </form>
                )}
            </div>
        </div>
        </div>
        </div>
        </div>
        
    );
}

export default FeedbackForm;
