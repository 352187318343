// FiltersForm.js
import React, { useState, useEffect, useRef  } from 'react';
import { Accordion, Row, Col, Button, Modal, Spinner } from 'react-bootstrap';
// import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import carData from './ssData/dropdownData/carData.json';
import './styles/1filters.scss';
import translations from './translate.js';
import trash from './img/trash.svg';
import pluss from './img/pluss.svg';
import { useTheme } from './ThemeContext';
import arrowRightIcon from './img/arrow-right.svg';
import arrowDownIcon from './img/arrow-down.svg';
function FiltersForm({ filterData, onStatusChange }) {
const [isActive, setIsActive] = useState(filterData.active === 1);
const [showInactiveAlert, setShowInactiveAlert] = useState(false);
const [showEngineDropdown, setShowEngineDropdown] = useState(false);
const [showBodyTypeDropdown, setShowBodyTypeDropdown] = useState(false);
const [showCarModelDropdown, setShowCarModelDropdown] = useState(false);
const [showColorDropdown, setShowColorDropdown] = useState(false);
const [showAreaDropdown, setShowAreaDropdown] = useState(false);
const [showVolume, setShowVolume] = useState(false);
const [isAccordionOpen, setIsAccordionOpen] = useState(false);
const [isDetailsExpanded, setIsDetailsExpanded] = useState(false);
const language = localStorage.getItem('language') || 'lv';
const text = translations[language];
const { theme } = useTheme();

    const [errorMessage, setErrorMessage] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [filterId, setFilterId] = useState(filterData ? filterData.filterId : null);
    
    const [filters, setFilters] = useState({
        filterName: 'Filter',
        priceMin: null,
        priceMax: null,
        yearMin: '-',
        yearMax: '-',
        volumeMin: null,
        volumeMax: null,
        runMin: null,
        runMax: null,
        carBrand: "0",
        gearBox: "0",
        checkup: null,
        dealType: "0",
        carModel: [],  
        engine: [],    
        bodyType: [],  
        color: [],     
        area: []  
    });

     // Dropdown refs for outside click detection
  const carModelDropdownRef = useRef(null);
  const engineDropdownRef = useRef(null);
  const bodyTypeDropdownRef = useRef(null);
  const colorDropdownRef = useRef(null);
  const areaDropdownRef = useRef(null);

  const toggleDetails = () => {
    setIsDetailsExpanded((prev) => !prev);
};
  
  const handleToggleActive = async () => {
    const newStatus = isActive ? 0 : 1; // Toggle between 1 (on) and 0 (off)
    setIsActive(!isActive);

    try {
      await axios.put(`/filters/update/${filterData.filterId}`, {
        filters: { ...filterData, active: newStatus }
      }, { withCredentials: true });

      // Notify FiltersPage with the new active status
      onStatusChange(filterData.filterId, newStatus);
    } catch (error) {
      console.error("Error updating filter status:", error);
      setIsActive(isActive); 
    }
  };

  const openDropdown = (setDropdownState, dropdownRef, isOpen = true) => {
    setDropdownState(isOpen);
    setTimeout(() => {
        if (dropdownRef.current) {
            dropdownRef.current.style.zIndex = isOpen ? 9999 : ''; // Atiestata z-index, ja dropdown ir aizvērts
        }
    }, 0);
};


// Toggle dropdowns with immediate open
const toggleCarModelDropdown = () => {
    const remainingCarModels = Object.entries(carData.car_brand[filters.carBrand].car_models)
      .filter(([modelValue, modelDisplay]) => modelDisplay !== "All" && !filters.carModel.includes(modelValue));
    if (remainingCarModels.length > 0) {
      openDropdown(setShowCarModelDropdown, carModelDropdownRef, true, remainingCarModels.length);
    } else {
      openDropdown(setShowCarModelDropdown, carModelDropdownRef, false);
    }
  };
  
  const toggleEngineDropdown = () => {
    const remainingEngines = Object.entries(carData.engine_type)
      .filter(([engineValue, engineDisplay]) => engineDisplay !== "All" && !filters.engine.includes(engineValue));
    if (remainingEngines.length > 0) {
      openDropdown(setShowEngineDropdown, engineDropdownRef, true, remainingEngines.length);
    } else {
      openDropdown(setShowEngineDropdown, engineDropdownRef, false);
    }
  };
  
  const toggleBodyTypeDropdown = () => {
    const remainingBodyTypes = Object.entries(carData.body_type)
      .filter(([bodyTypeValue, bodyTypeDisplay]) => bodyTypeDisplay !== "All" && !filters.bodyType.includes(bodyTypeValue));
    if (remainingBodyTypes.length > 0) {
      openDropdown(setShowBodyTypeDropdown, bodyTypeDropdownRef, true, remainingBodyTypes.length);
    } else {
      openDropdown(setShowBodyTypeDropdown, bodyTypeDropdownRef, false);
    }
  };
  
  const toggleColorDropdown = () => {
    const remainingColors = Object.entries(carData.color)
      .filter(([colorValue, colorDisplay]) => colorDisplay !== "All" && !filters.color.includes(colorValue));
    if (remainingColors.length > 0) {
      openDropdown(setShowColorDropdown, colorDropdownRef, true, remainingColors.length);
    } else {
      openDropdown(setShowColorDropdown, colorDropdownRef, false);
    }
  };
  
  const toggleAreaDropdown = () => {
    const remainingAreas = Object.entries(carData.area)
      .filter(([areaValue, areaDisplay]) => areaDisplay !== "All" && !filters.area.includes(areaValue));
    if (remainingAreas.length > 0) {
      openDropdown(setShowAreaDropdown, areaDropdownRef, true, remainingAreas.length);
    } else {
      openDropdown(setShowAreaDropdown, areaDropdownRef, false);
    }
  };
  
  
useEffect(() => {
    const handleClickOutside = (event) => {
        if (carModelDropdownRef.current && !carModelDropdownRef.current.contains(event.target)) {
            openDropdown(setShowCarModelDropdown, carModelDropdownRef, false);
        }
        if (engineDropdownRef.current && !engineDropdownRef.current.contains(event.target)) {
            openDropdown(setShowEngineDropdown, engineDropdownRef, false);
        }
        if (bodyTypeDropdownRef.current && !bodyTypeDropdownRef.current.contains(event.target)) {
            openDropdown(setShowBodyTypeDropdown, bodyTypeDropdownRef, false);
        }
        if (colorDropdownRef.current && !colorDropdownRef.current.contains(event.target)) {
            openDropdown(setShowColorDropdown, colorDropdownRef, false);
        }
        if (areaDropdownRef.current && !areaDropdownRef.current.contains(event.target)) {
            openDropdown(setShowAreaDropdown, areaDropdownRef, false);
        }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, []);

  
  // Add and remove for Car Model
  const handleAddCarModel = (modelValue) => {
    if (!filters.carModel.includes(modelValue)) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        carModel: [...prevFilters.carModel, modelValue],
      }));
      openDropdown(setShowCarModelDropdown, carModelDropdownRef, false); // Aizver dropdown
    }
  };

  const handleRemoveCarModel = (modelValue) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      carModel: prevFilters.carModel.filter((model) => model !== modelValue),
    }));
  };
  

// useEffect to handle visibility of volume input based on engine selection
useEffect(() => {
    setShowVolume(!(filters.engine.length === 1 && filters.engine.includes("electric")));
}, [filters.engine]);

const handleAddEngine = (engineValue) => {
    if (!filters.engine.includes(engineValue)) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        engine: [...prevFilters.engine, engineValue],
      }));
      openDropdown(setShowEngineDropdown, engineDropdownRef, false); // Aizver dropdown
    }
  };

const handleRemoveEngine = (engineToRemove) => {
    setFilters((prevFilters) => ({
        ...prevFilters,
        engine: prevFilters.engine.filter((engine) => engine !== engineToRemove),
    }));
};


const handleAddBodyType = (bodyTypeValue) => {
    if (!filters.bodyType.includes(bodyTypeValue)) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        bodyType: [...prevFilters.bodyType, bodyTypeValue],
      }));
      openDropdown(setShowBodyTypeDropdown, bodyTypeDropdownRef, false); // Aizver dropdown
    }
  };
  
  const handleRemoveBodyType = (bodyTypeValue) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      bodyType: prevFilters.bodyType.filter((bodyType) => bodyType !== bodyTypeValue),
    }));
  };
  
  const handleAddColor = (colorValue) => {
    if (!filters.color.includes(colorValue)) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        color: [...prevFilters.color, colorValue],
      }));
      openDropdown(setShowColorDropdown, colorDropdownRef, false); // Aizver dropdown
    }
  };
  
  const handleRemoveColor = (colorValue) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      color: prevFilters.color.filter((color) => color !== colorValue),
    }));
  };
  const handleAddArea = (areaValue) => {
    if (!filters.area.includes(areaValue)) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        area: [...prevFilters.area, areaValue],
      }));
      openDropdown(setShowAreaDropdown, areaDropdownRef, false); 
    }
  };
  
  const handleRemoveArea = (areaValue) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      area: prevFilters.area.filter((area) => area !== areaValue),
    }));
  };
  
    // const generateYears = (startYear, endYear) => {
    //     const years = [];
    //     for (let year = startYear; year <= endYear; year++) {
    //         years.push(year);
    //     }
    //     return years;
    // };

    // const yearsMin = filters.yearMax === '-' ? generateYears(1900, new Date().getFullYear()).concat('-') : generateYears(1900, filters.yearMax).concat('-');
    // const yearsMax = filters.yearMin === '-' ? generateYears(1900, new Date().getFullYear()).concat('-') : generateYears(filters.yearMin, new Date().getFullYear()).concat('-');

    const generateCheckUpMonths = () => {
        const currentDate = new Date();
        const endDate = new Date(currentDate);
        endDate.setFullYear(endDate.getFullYear() + 2);
        endDate.setMonth(endDate.getMonth() + 1);

        const checkUpMonths = [];

        while (currentDate < endDate) {
            const monthText = currentDate.toLocaleDateString('default', { month: 'short', year: 'numeric' });
            const monthValue = `${(currentDate.getMonth() + 1).toString().padStart(2, '0')}.${currentDate.getFullYear()}`;
            checkUpMonths.push({ text: monthText, value: monthValue });
            currentDate.setMonth(currentDate.getMonth() + 1);
        }

        return checkUpMonths;
    };

    useEffect(() => {
        if (filterData) {
            setFilters({
                filterName: filterData.filterName || 'Filter',
                priceMin: filterData.priceMin || null,
                priceMax: filterData.priceMax || null,
                yearMin: filterData.yearMin || '-',
                yearMax: filterData.yearMax || '-',
                volumeMin: filterData.volumeMin || null,
                volumeMax: filterData.volumeMax || null,
                runMin: filterData.runMin || null,
                runMax: filterData.runMax || null,
                carBrand: filterData.carBrand || "0",
                gearBox: filterData.gearBox || "0",
                checkup: filterData.checkup || null,
                dealType: filterData.dealType || "0",
                carModel: filterData.carModel || [],  
                engine: filterData.engine || [],
                bodyType: filterData.bodyType || [],
                color: filterData.color || [],
                area: filterData.area || []
            });
            setFilterId(filterData.filterId);  
        }
    }, [filterData]);

   const handleChange = (event) => {
    const { name, value } = event.target;

    let updatedFilters = {
        ...filters,
        [name]: value === "0" ? null : value, 
    };

    
    if (name === 'carBrand') {
        updatedFilters = {
            ...updatedFilters,
            carModel: [], 
        };
    }
    
    if (name === 'engine' && value === "0") {
        updatedFilters = {
            ...updatedFilters,
            engine: []  
        };
    }
    
    if (name === 'bodyType' && value === "0") {
        updatedFilters = {
            ...updatedFilters,
            bodyType: [] 
        };
    }
    
    if (name === 'color' && value === "0") {
        updatedFilters = {
            ...updatedFilters,
            color: [] 
        };
    }
    
    if (name === 'area' && value === "0") {
        updatedFilters = {
            ...updatedFilters,
            area: [] 
        };
    }
    
    setFilters(updatedFilters);
};

const getMaxFilters = (role) => {
    switch (role) {
        case 'admin':
            return 20;
        case 'advanced':
            return 10;
        case 'basic':
            return 5;
        default:
            return 0;
    }
};

const handleValidation = async () => {
    try {
        setIsSaving(true);

        // Fetch the language from localStorage or default to 'en'
        const selectedLanguage = localStorage.getItem('language') || 'en';

        // Fetch combined count of active filters
        const combinedCountResponse = await axios.get('/filters/get/combined-count', {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'language': selectedLanguage,
            },
        });
        const combinedCount = combinedCountResponse.data.combinedCount;

        const maxFilters = getMaxFilters();

        // Determine active status
        if (combinedCount >= maxFilters) {
            filters.active = 0;
        }

        const url = filterId == null ? '/filters/new' : `/filters/update/${filterId}`;
        const method = filterId == null ? 'post' : 'put';
        const response = await axios[method](url, { filters }, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'language': selectedLanguage,
            },
        });

        if (response.status === 200) {
            setErrorMessage(false);
            setFilterId(response.data.filterId || filterId);

            if (response.data.active === 0) {
                setShowInactiveAlert(true);

                setTimeout(() => {
                    setShowInactiveAlert(false);
                    window.location.reload();
                }, 5000);
            } else {
                window.location.reload();
            }
        }
    } catch (error) {
        console.error('Error during save:', error.response || error.message);
        if (error.response && error.response.data) {
            setErrorMessage(error.response.data.message);
        } else {
            setErrorMessage('Unexpected error occurred during save.');
        }
    } finally {
        setIsSaving(false);
    }
};

    const handleDelete = async () => {
        setIsDeleting(true);
        if (filterId != null) {
            try {
                await axios.delete(`/filters/delete/${filterId}`, {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setShowDeleteModal(false);
                window.location.reload();
            } catch (error) {
                console.error(error);
            } finally {
                setIsDeleting(false);
            }
        }
    };

    return (
         <div data-theme={theme}>
        <div>
            <div className="filters-row">
            <div className="container-fluid" id='filters-container'>
                <form>
                    <Accordion.Item eventKey={filterId == null ? 0 : filterId}>
                    <Accordion.Header 
                        className="custom-accordion-header" 
                          onClick={() => setIsAccordionOpen(prevState => !prevState)}
                            >
                        {filters.filterName}
                            </Accordion.Header>
                            <Accordion.Body>

                            {/* Filter Name */}
                            <div className="input-container">
                                <div className="input-group">
                                    <Form.Control 
                                        type="text" 
                                        placeholder={text.filterNameLabel}
                                        name='filterName' 
                                        value={filters.filterName}
                                        onChange={handleChange} 
                                        maxLength={30} 
                                        required 
                                    />
                                </div>
                            </div>

                            <div className="row input-row">
                            {/* Car Brand */}
<div className="col-md-6 input-container">
    <div className="floating-input">
        <Form.Select
            name="carBrand"
            value={filters.carBrand === '0' ? '' : filters.carBrand}
            onChange={handleChange}
            className="form-control"
            required
        >
            {/* Placeholder */}
            <option value="" disabled hidden>
                {text.carBrandLabel} 
            </option>
            {/* Dropdown options */}
            {Object.entries(carData.car_brand).map(([key, { display, value }]) => (
                <option key={key} value={value}>
                    {display}
                </option>
            ))}
        </Form.Select>
    </div>
</div>


    {/* Car Model */}
    {filters.carBrand && filters.carBrand !== "0" && (
        <div className="col-md-6 input-container">
            <div className="car-model-field " ref={carModelDropdownRef} onClick={(e) => e.stopPropagation()}>
                {/* Placeholder and Selected Models */}
                <div className="selected-models-container">
                    {filters.carModel.length === 0 && !showCarModelDropdown ? (
                        <span className="placeholder-text">
                            {text.carModelLabel} {/* Placeholder text */}
                        </span>
                    ) : (
                        filters.carModel.map((model, index) => (
                            <div
                                key={index}
                                className="selected-model"
                                onClick={() => handleRemoveCarModel(model)}
                            >
                                <span>
                                    {
                                        carData.car_brand[filters.carBrand].car_models[
                                            model
                                        ]
                                    }
                                </span>
                                <button
                                    type="button"
                                    className="remove-btn"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        handleRemoveCarModel(model);
                                    }}
                                >
                                    &times;
                                </button>
                            </div>
                        ))
                    )}
                </div>

                {/* Add Button */}
                <div className="add-button-container">
                    <button
                        type="button"
                        className="add-button"
                        onClick={() => toggleCarModelDropdown()} // Toggle dropdown
                    >
                        <img
                            src={pluss}
                            alt="Add Icon"
                            className="add-icon"
                        />
                        {text.addBtn}
                    </button>
                </div>

                {/* Dropdown List */}
                {showCarModelDropdown && (
                    <div
                        className="dropdown-list above-elements"
                        ref={(el) => {
                            if (el) {
                                const containerWidth =
                                    carModelDropdownRef.current.offsetWidth;
                                el.style.width = `${containerWidth}px`; 
                            }
                        }}
                    >
                        {Object.entries(
                            carData.car_brand[filters.carBrand].car_models
                        )
                            .filter(
                                ([modelValue, modelDisplay]) =>
                                    modelDisplay !== "All" &&
                                    !filters.carModel.includes(modelValue)
                            )
                            .map(([modelValue, modelDisplay]) => (
                                <div
                                    key={modelValue}
                                    className="dropdown-item"
                                    onClick={() =>
                                        handleAddCarModel(modelValue)
                                    }
                                >
                                    {modelDisplay}
                                </div>
                            ))}
                    </div>
                )}
            </div>
        </div>
    )}
</div>

                        {/* Price */}
<div className="input-container">
    <div className="input-group">
        <input
            id="cool-control-left"
            type="number"
            placeholder={text.priceFrom} 
            name="priceMin"
            value={filters.priceMin || ''} 
            onChange={handleChange}
            min="0"
            className="form-control"
        />
        <span className="input-group-text">-</span>
        <input
            id="cool-control-right"
            type="number"
            placeholder={text.priceTo} 
            name="priceMax"
            value={filters.priceMax || ''} 
            onChange={handleChange}
            min="0"
            className="form-control"
        />
    </div>
</div>

                    {/* Year */}
<div className="input-container">
    <div className="input-group">
        <input
            id="cool-control-left"
            type="number"
            placeholder={text.yearFrom} 
            name="yearMin"
            value={filters.yearMin === '-' ? '' : filters.yearMin}
            onChange={handleChange}
            min="1900" 
            max={new Date().getFullYear()} 
            className="form-control"
        />
        <span className="input-group-text">-</span>
        <input
            id="cool-control-right"
            type="number"
            placeholder={text.yearTo} 
            name="yearMax"
            value={filters.yearMax === '-' ? '' : filters.yearMax} 
            onChange={handleChange}
            min="1900" 
            max={new Date().getFullYear()} 
            className="form-control"
        />
    </div>
</div>

<div className="row input-row">
    {/* Color */}
    <div className="col-md-6 input-container" ref={colorDropdownRef}>
        <div className="car-model-field">
            <div className="selected-models-container">
                {filters.color.length === 0 ? (
                    <span className="placeholder-text">{text.colorLabel}</span>
                ) : (
                    filters.color.map((color, index) => (
                        <div
                            key={index}
                            className="selected-model"
                            onClick={() => handleRemoveColor(color)}
                        >
                            <span>{carData.color[color]}</span>
                            <button
                                type="button"
                                className="remove-btn"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleRemoveColor(color);
                                }}
                            >
                                &times;
                            </button>
                        </div>
                    ))
                )}
            </div>
            <div className="add-button-container">
                <button
                    type="button"
                    className="add-button"
                    onClick={() => toggleColorDropdown()}
                >
                    <img src={pluss} alt="Add Icon" className="add-icon" />
                    {text.addBtn}
                </button>
            </div>
            {showColorDropdown && (
                <div className="dropdown-list">
                    {Object.entries(carData.color)
                        .filter(
                            ([colorValue, colorDisplay]) =>
                                colorDisplay !== "All" &&
                                !filters.color.includes(colorValue)
                        )
                        .map(([colorValue, colorDisplay]) => (
                            <div
                                key={colorValue}
                                className="dropdown-item"
                                onClick={() => handleAddColor(colorValue)}
                            >
                                {colorDisplay}
                            </div>
                        ))}
                </div>
            )}
        </div>
    </div>

    {/* Area */}
    <div className="col-md-6 input-container" ref={areaDropdownRef}>
        <div className="car-model-field">
            <div className="selected-models-container">
                {filters.area.length === 0 ? (
                    <span className="placeholder-text">{text.areaLabel}</span>
                ) : (
                    filters.area.map((area, index) => (
                        <div
                            key={index}
                            className="selected-model"
                            onClick={() => handleRemoveArea(area)}
                        >
                            <span>{carData.area[area]}</span>
                            <button
                                type="button"
                                className="remove-btn"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleRemoveArea(area);
                                }}
                            >
                                &times;
                            </button>
                        </div>
                    ))
                )}
            </div>
            <div className="add-button-container">
                <button
                    type="button"
                    className="add-button"
                    onClick={() => toggleAreaDropdown()}
                >
                    <img src={pluss} alt="Add Icon" className="add-icon" />
                    {text.addBtn}
                </button>
            </div>
            {showAreaDropdown && (
                <div className="dropdown-list">
                    {Object.entries(carData.area)
                        .filter(
                            ([areaValue, areaDisplay]) =>
                                areaDisplay !== "All" &&
                                !filters.area.includes(areaValue)
                        )
                        .map(([areaValue, areaDisplay]) => (
                            <div
                                key={areaValue}
                                className="dropdown-item"
                                onClick={() => handleAddArea(areaValue)}
                            >
                                {areaDisplay}
                            </div>
                        ))}
                </div>
            )}
        </div>
    </div>
</div>

{/* Detalizētie filtri */}
<div className="details-section mt-4">
    <div className="details-toggle d-flex align-items-center">
        <span
            className="me-2"
            onClick={() => toggleDetails('text')}
        >
            {text.details}
        </span>
        <img
            src={isDetailsExpanded ? arrowDownIcon : arrowRightIcon}
            alt="Toggle Details"
            className="details-toggle-icon"
            onClick={() => toggleDetails('image')}
        />
    </div>

    {isDetailsExpanded && (
        <div className="details-content mt-3">

<div className="row input-row">
    {/* Engine Type */}
    <div className="col-md-6 input-container" ref={engineDropdownRef}>
        <div className="car-model-field">
            <div className="selected-models-container">
                {filters.engine.length === 0 ? (
                    <span className="placeholder-text">{text.engineTypeLabel}</span>
                ) : (
                    filters.engine.map((engine, index) => (
                        <div
                            key={index}
                            className="selected-model"
                            onClick={() => handleRemoveEngine(engine)}
                        >
                            <span>{carData.engine_type[engine]}</span>
                            <button
                                type="button"
                                className="remove-btn"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleRemoveEngine(engine);
                                }}
                            >
                                &times;
                            </button>
                        </div>
                    ))
                )}
            </div>
            <div className="add-button-container">
                <button
                    type="button"
                    className="add-button"
                    onClick={() => toggleEngineDropdown()}
                >
                    <img src={pluss} alt="Add Icon" className="add-icon" />
                    {text.addBtn}
                </button>
            </div>
            {showEngineDropdown && (
                <div className="dropdown-list">
                    {Object.entries(carData.engine_type)
                        .filter(
                            ([engineValue, engineDisplay]) =>
                                engineDisplay !== "All" &&
                                !filters.engine.includes(engineValue)
                        )
                        .map(([engineValue, engineDisplay]) => (
                            <div
                                key={engineValue}
                                className="dropdown-item"
                                onClick={() => handleAddEngine(engineValue)}
                            >
                                {engineDisplay}
                            </div>
                        ))}
                </div>
            )}
        </div>
    </div>

    {/* Body Type */}
    <div className="col-md-6 input-container" ref={bodyTypeDropdownRef}>
        <div className="car-model-field">
            <div className="selected-models-container">
                {filters.bodyType.length === 0 ? (
                    <span className="placeholder-text">{text.bodyTypeLabel}</span>
                ) : (
                    filters.bodyType.map((bodyType, index) => (
                        <div
                            key={index}
                            className="selected-model"
                            onClick={() => handleRemoveBodyType(bodyType)}
                        >
                            <span>{carData.body_type[bodyType]}</span>
                            <button
                                type="button"
                                className="remove-btn"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleRemoveBodyType(bodyType);
                                }}
                            >
                                &times;
                            </button>
                        </div>
                    ))
                )}
            </div>
            <div className="add-button-container">
                <button
                    type="button"
                    className="add-button"
                    onClick={() => toggleBodyTypeDropdown()}
                >
                    <img src={pluss} alt="Add Icon" className="add-icon" />
                    {text.addBtn}
                </button>
            </div>
            {showBodyTypeDropdown && (
                <div className="dropdown-list">
                    {Object.entries(carData.body_type)
                        .filter(
                            ([bodyTypeValue, bodyTypeDisplay]) =>
                                bodyTypeDisplay !== "All" &&
                                !filters.bodyType.includes(bodyTypeValue)
                        )
                        .map(([bodyTypeValue, bodyTypeDisplay]) => (
                            <div
                                key={bodyTypeValue}
                                className="dropdown-item"
                                onClick={() => handleAddBodyType(bodyTypeValue)}
                            >
                                {bodyTypeDisplay}
                            </div>
                        ))}
                </div>
            )}
        </div>
    </div>
</div>



           {/* Volume */}
{showVolume && (
    <div className="input-container mt-3">
        <div className="input-group">
            <input
                id="cool-control-left"
                type="number"
                step=".1"
                placeholder={text.volumeFrom} 
                name="volumeMin"
                value={filters.volumeMin || ''} 
                onChange={handleChange}
                min="0" 
                className="form-control"
            />
            <span className="input-group-text">-</span>
            <input
                id="cool-control-right"
                type="number"
                step=".1"
                placeholder={text.volumeTo} 
                name="volumeMax"
                value={filters.volumeMax || ''} 
                onChange={handleChange}
                min="0" 
                className="form-control"
            />
        </div>
    </div>
)}


<div className="row input-row">
    {/* Gearbox */}
<div className="col-md-6 input-container">
    <div className="floating-input">
        <Form.Select
            name="gearBox"
            value={filters.gearBox === '0' ? '' : filters.gearBox}
            onChange={handleChange}
            className="form-control"
            required
        >
            {/* Placeholder */}
            <option value="" disabled hidden>
                {text.gearboxLabel} 
            </option>
            {/* Dropdown options */}
            {Object.entries(carData.gearbox).map(([value, displayText]) => (
                <option key={value} value={value}>
                    {displayText}
                </option>
            ))}
        </Form.Select>
    </div>
</div>


    {/* Checkup */}
<div className="col-md-6 input-container">
    <div className="floating-input">
        <Form.Select
            name="checkup"
            value={filters.checkup ?? ''} 
            onChange={(e) => {
                const value = e.target.value === 'any' ? 'any' : e.target.value;
                handleChange({ target: { name: e.target.name, value } });
            }}
            className="form-control"
            required
        >
            {/* Placeholder */}
            <option value="" disabled hidden>
                {text.checkupLabel} 
            </option>
            {/* Opcija "Any" */}
            <option value="any">
                {text.checkupAnyLabel}
            </option>
            {/* Ģenerētās opcijas */}
            {generateCheckUpMonths().map((month) => (
                <option key={month.value} value={month.value}>
                    {month.text}
                </option>
            ))}
        </Form.Select>
    </div>
</div>

</div>

                            { /* Run Distance */}
                    <div className="input-container mt-3">
                        <div className="input-group">
                            <input
                                id="cool-control-left"
                                type="number"
                                placeholder={text.runDistanceFrom} 
                                name="runMin"
                                value={filters.runMin || ''} 
                                onChange={handleChange}
                                min="0" 
                                className="form-control"
                            />
                            <span className="input-group-text">-</span>
                            <input
                                id="cool-control-right"
                                type="number"
                                placeholder={text.runDistanceTo} 
                                name="runMax"
                                value={filters.runMax || ''} 
                                onChange={handleChange}
                                min="0" 
                                className="form-control"
                            />
                        </div>
                    </div>


                    {/* Deal Type */}
        <div className="input-container mt-3">
            <div className="floating-input">
                <Form.Select
                    name="dealType"
                    value={filters.dealType === '0' ? '' : filters.dealType}
                    onChange={handleChange}
                    className="form-control"
                    required
                >
                   <option value="" disabled hidden>
                    {text.dealTypeLabel} 
                </option>
                    {Object.entries(carData.deal_type).map(([value, text]) => (
                        <option key={value} value={value}>
                            {text}
                        </option>
                    ))}
                </Form.Select>
            </div>
        </div>
        
        </div>
    )}
</div>

                            {/* Buttons */}
                            <Row>
                                <Col xs={filterId ? 8 : 12} sm={filterId ? 9 : 12} md={filterId ? 10 : 12} xxl={filterId ? 11 : 12} className="mt-3">
                                    <Button variant="primary" className="btn-save" onClick={handleValidation} type="button" block>
                                        {isSaving ? (
                                            <>
                                                <Spinner animation="border" size="sm" className="mr-2" />
                                                {text.savingText}
                                            </>
                                        ) : (
                                            text.saveButton
                                        )}
                                    </Button>
                                </Col>
                                {/* Only show the delete button (if filterId exists) */}
                                {filterId && (
                            <Col xs={4} sm={3} md={2} xxl={1} className="mt-3">
                                <Button 
                                    variant="danger" 
                                    className="btn-delete d-flex justify-content-center align-items-center" 
                                    onClick={() => setShowDeleteModal(true)}
                                >
                                    <img 
                                        src={trash} 
                                        alt="Trash Icon" 
                                        style={{ width: '16px', height: '16px', marginRight: '8px' }} 
                                    />
                                    <span>{text.deleteButton}</span>
                                </Button>
                            </Col>
                            )}

                            </Row>
                            {/* Inactive Filter Alert Modal */}
                <Modal show={showInactiveAlert} onHide={() => setShowInactiveAlert(false)} centered>
                    <Modal.Body>
                    {text.inactiveFilterAlert}
                    </Modal.Body>
                </Modal>
                            {errorMessage && <div className="error-message text-center">{errorMessage}</div>}
                            {/* Confirm Delete Modal */}
                            <Modal className="delete-modal" show={showDeleteModal} onHide={() => {
                                setShowDeleteModal(false);
                                setIsDeleting(false);
                            }}>
                                <Modal.Header closeButton className="delete-modal-header">
                                    <Modal.Title>{text.deleteConfirmationTitle}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body dangerouslySetInnerHTML={{ __html: text.deleteConfirmationBody.replace("{filterName}", filters.filterName) }} />
                                <Modal.Footer>
                                    {!isDeleting && (
                                        <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                                            {text.cancelButton}
                                        </Button>
                                    )}
                                    <Button variant="danger" onClick={handleDelete}>
                                        {isDeleting ? (
                                            <>
                                                <Spinner animation="border" size="sm" className="mr-2" />
                                                {text.deletingText}
                                            </>
                                        ) : text.confirmButton}
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </Accordion.Body>
                    </Accordion.Item>
                </form>
            </div>
             {/* Toggle switch for active status - only visible after filter is saved */}
             {filterId && !isAccordionOpen && (
    <div className="filter-toggle">
        <span>{isActive}</span>
        <Form.Check
            type="switch"
            id={`filter-switch-${filterId}`}
            checked={isActive}
            onChange={handleToggleActive}
            label=""
        />
    </div>
)}
</div>
        </div>
        </div>
    );
}

export default FiltersForm;
